import React from 'react';
import { graphql } from 'gatsby';
import { Content, Header, Layout, SectionTitle } from '../components';
import { Helmet } from 'react-helmet';
import SiteConfig from '../../config/SiteConfig';
import Navigation from '../components/Navigation';
import styled from 'styled-components';
import PhotoPost from '../models/PhotoPost';
import { PhotoQuery } from '../models/PhotoQuery';
import { AuthorQuery } from '../models/AuthorQuery';
import { PhotoGalleryPost } from '../posts/photos/PhotoGalleryPost';

const NavigationArea = styled.div`
  grid-area: navigation;
  position: relative;
`;


interface PostRef {
  type: string;
  id: string;
  path: string;
  date: string;
  photo?: PhotoPost;
}


interface Props {
  data: GalleryPageData;
  pageContext: {
    name: string;
    slug: string;
    month: string;
    posts: PostRef[];
  };
}


export default class GalleryPage extends React.Component<Props> {

  public render() {
    const { data, pageContext } = this.props;
    const photo = data.photo.edges[0].node;
    const author = data.author.edges[0].node;
    const month = pageContext.month;

    return (
      <Layout>
        <Helmet title={`Blog | ${SiteConfig.siteTitle}`}/>
        <Header linkToHomepage={true}>
          <SectionTitle uppercase={true}>{
            (photo.caption && photo.caption.length < 120) ? photo.caption : 'Photo'
          }</SectionTitle>
        </Header>
        <NavigationArea>
          <Navigation selected={month} linkSelected={true} />
        </NavigationArea>
        <Content>
          <PhotoGalleryPost key={photo.id} photo={photo} author={author} />
        </Content>
      </Layout>
    );
  }
}


interface GalleryPageData {
  photo: PhotoQuery;
  author: AuthorQuery;
}

export const GalleryPageQuery = graphql`
  query($id: String) {
    photo: allPhotosJson(filter: {id: {eq: $id }}) {
      edges {
        ...PhotoNode
      }
    },
    author: allPeopleJson(filter: { key: { eq: "charlesgutjahr" }}) {
      edges {
        node {
          key
          name
          profile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 36)
            }
          }
        }
      }
    }
  }
`;
