import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import PhotoPost from '../../models/PhotoPost';
// @ts-ignore
import { GenericPhotoPost } from './GenericPhotoPost';
import { media } from '../../utils/media';
import { Helmet } from 'react-helmet';


interface PhotoContainerProps {
  width: number;
  height: number;
}

const PhotoContainer = styled.div`
  @media ${media.notphone} {
    width: ${(props: PhotoContainerProps) => props.width}px;
    height: ${(props: PhotoContainerProps) => props.height}px;
  }
`;


const GatsbyImageStyled = styled(GatsbyImage)`
  max-width: 612px;
`;

interface PhotoGalleryPostProps {
  photo: PhotoPost;
  author: any;
  links?: {
    instagram?: {
      url: string;
    };
  };
}

export class PhotoGalleryPost extends React.PureComponent<PhotoGalleryPostProps> {

  public render() {
    const { photo, author } = this.props;
    const columns = photo.gallery ? Math.min(photo.gallery.length, 3) : 1;
    return (
      <GenericPhotoPost photo={photo} author={author} columns={columns}>
        <>
          {photo.gallery && photo.gallery.map((galleryPhoto, index) => {
            const width = 612;
            const ratio = width / galleryPhoto.width;
            const height = ratio * galleryPhoto.height;
            return (
              <PhotoContainer key={index} width={width} height={height}>
                { galleryPhoto.media && (
                  <GatsbyImageStyled image={galleryPhoto.media.childImageSharp.gatsbyImageData} alt={photo.caption || ''} />
                )}
              </PhotoContainer>
            );
          })}
          {photo.gallery && photo.gallery[0] && photo.gallery[0].media && (
            <Helmet>
              <meta property="og:image" content={getSrc(photo.gallery[0].media)} />
            </Helmet>
          )}
        </>
      </GenericPhotoPost>
    );
  }
}

